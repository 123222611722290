import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { grid, wrapper, loginForm } from './sign-in.module.scss';
import { ISection } from '../../models/section.model';

import Section from '../hoc/section';
import HorizontalTabNav from '../molecules/horizontal-tab-nav';
import LoginFormWithLink from '../molecules/login-form-with-link';
import useTranslations from '../../hooks/use-translations';

type TStaticQueryResult = Record<'login' | 'register', { pathname: string } | null>;

export interface ISignInProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISection;
}

export default function SignIn({ className, section, TitleTag }: ISignInProps) {
    const { sectionId, css, style } = section;
    const routes = useStaticQuery<TStaticQueryResult>(query);
    const formTranslations = useTranslations('SignInUpNav');

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{ container: grid }}
            TitleTag={TitleTag}
            css={css}
            style={style}
        >
            <div className={wrapper}>
                {routes.login && routes.register && (
                    <HorizontalTabNav
                        links={[
                            { to: routes.login.pathname, label: formTranslations.links.login },
                            {
                                to: routes.register.pathname,
                                label: formTranslations.links.register,
                            },
                        ]}
                    />
                )}
                <LoginFormWithLink className={loginForm} />
            </div>
        </Section>
    );
}

const query = graphql`
    query {
        login: page(type: { eq: "login" }) {
            pathname
        }
        register: page(type: { eq: "register" }) {
            pathname
        }
    }
`;
